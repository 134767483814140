import React, { useEffect, useMemo, useState } from 'react';
import { useForm, get, FieldValues } from 'react-hook-form';
import { saveAs } from 'file-saver';
import { useSelector, useDispatch } from 'react-redux';
import { ErrorField, StyledSelect } from '../../lib/HooksFormFields';
import styles from './ExportCompare.module.scss';
import { exportXlsxAction, getExportLists } from '../../actions/export';
import { generateMonthsObjects } from '../../utils';

const ExportCompare = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string | null>(null);
  const userAuth = useSelector((store: any) => store.authReducer)?.user;

  const {
    exportList,
  } = useSelector((store: any) => store.shiftsReducer);

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
    setValue,
  } = useForm<FieldValues>();

  const form = watch();

  const options = useMemo(() => {
    const opt : any = {
      users: [],
      poles: [],
      startDate: [{ value: null, label: 'Séléctionner' }],
      endDate: [{ value: null, label: 'Séléctionner' }],
    };
    if (form.structure && exportList?.users) {
      opt.users = exportList?.users.filter((u: any) => u.structure === form.structure);
      const poles = exportList?.structures.find((u: any) => u.value === form.structure)?.poles;
      if (poles.length) {
        opt.poles = [...poles];
      }
      if (form.period) {
        const months = generateMonthsObjects(form.period.startDate, form.period.endDate);
        months.forEach((m:any) => {
          opt.startDate.push({ value: m.start, label: m.month });
          opt.endDate.push({ value: m.end, label: m.month });
        });
        const today = new Date();
        const current = months.findIndex(
          (m) => new Date(m.start).getMonth() === today.getMonth(),
        );
        const prev = months.findIndex(
          (m) => new Date(m.start).getMonth() === today.getMonth() - 1,
        );
        if (current !== -1 && !form?.endDate) {
          setValue('endDate', months[current]?.end);
        }
        if (prev !== -1 && !form?.startDate) {
          console.log(prev);
          setValue('startDate', months[prev]?.start);
        }
      }
      if (form.startDate) {
        opt.endDate = [{ value: null, label: 'Séléctionner' }, ...opt.endDate.filter((w: any) => new Date(w.value) > new Date(form.startDate))];
      }
    }
    return opt;
  }, [exportList, form.structure, form.period, form.startDate]);

  async function onSubmit(values: FieldValues) {
    setIsLoading(true);
    setApiError(null);
    const poles = options.poles.filter(
      (p:any) => values?.poles?.find((po: any) => po === p.value) && p.isPole,
    ).map((p: any) => p.value);
    const data = {
      type: values.type,
      startDate: values.startDate,
      endDate: values.endDate,
      structure: values.structure,
      poles,
      users: values.users || [],
    };
    try {
      const startMonth = options.startDate.find((d:any) => d.value === data.startDate)?.label.replace(' ', '-');
      const endMonth = options.endDate.find((d:any) => d.value === data.endDate)?.label.replace(' ', '-');
      const structure = exportList?.structures?.find((u: any) => u.value === data.structure)?.label;
      let name = 'analyse';
      if (structure) {
        name += `-${structure.replaceAll(' ', '-')}`;
      }
      if (startMonth === endMonth) {
        name += `${startMonth}`;
      } else {
        name += `-${startMonth}-${endMonth}`;
      }
      const res : any = await exportXlsxAction(dispatch, data);
      if (res) {
        const blob = new Blob([res]);
        const filename = `${name}.xlsx`;
        // Use file-saver to save the blob
        saveAs(blob, filename);
      }
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      setApiError('Une erreur c\'est produite lors de l\'export');
    }
  }

  useEffect(() => {
    if (form.period) {
      setValue('endDate', null);
      setValue('startDate', null);
    }
  }, [form.period]);

  useEffect(() => {
    getExportLists(dispatch);
  }, []);

  useEffect(() => {
    const foundPeriod = exportList?.periods?.find((p:any) => p.value?.isCurrent);
    if (foundPeriod) {
      reset({
        type: 'compare',
        structure: localStorage.getItem('structure'),
        period: foundPeriod?.value,
      });
    }
  }, [exportList?.periods]);

  return (
    <div className={styles.modale}>
      <div className={styles.form}>
        <div className={styles.row}>
        {(userAuth.role === 'Admin' || userAuth.role === 'Owner')
          && <div className={styles['container-field']}>
            <StyledSelect
                label={'Structure'}
                name="structure"
                control={control}
                options={exportList.structures}
                rules={{
                  required: 'la structure est requise',
                }}
            />
            {typeof get(errors, 'structure')?.message === 'string' && (
              <ErrorField message={get(errors, 'structure')?.message || ''}/>
            )}
          </div>
        }
        <div className={styles['container-field']}>
          <StyledSelect
              name="period"
              label={'Période'}
              control={control}
              options={exportList?.periods}
              rules={{
                required: 'la période est requise',
              }}
          />
          {typeof get(errors, 'period')?.message === 'string' && (
            <ErrorField message={get(errors, 'period')?.message || ''}/>
          )}
        </div>
      </div>
      {options.startDate
        && <div className={styles.row}>
          <div className={styles['container-field']}>
            <StyledSelect
                name="startDate"
                label={'Mois de départ'}
                control={control}
                options={options.startDate}
                rules={{
                  required: 'le mois de départ est requis',
                }}
            />
            {typeof get(errors, 'startDate')?.message === 'string' && (
              <ErrorField message={get(errors, 'startDate')?.message || ''}/>
            )}
          </div>
          <div className={styles['container-field']}>
            <StyledSelect
                name="endDate"
                label={'Mois de fin'}
                control={control}
                options={options.endDate}
                rules={{
                  required: 'le mois de départ est requis',
                }}
            />
            {typeof get(errors, 'endDate')?.message === 'string' && (
              <ErrorField message={get(errors, 'endDate')?.message || ''}/>
            )}
          </div>
        </div>
      }
      {form?.endDate
        && <div className={styles.row}>
          <div className={styles['container-field']}>
            <StyledSelect
              name="users"
              label={'Membres'}
              control={control}
              isMulti={true}
              options={options.users}
            />
          </div>
          <div className={styles['container-field']}>
            <StyledSelect
              name="poles"
              label={'Pôles'}
              control={control}
              isMulti={true}
              options={options.poles}
            />
          </div>
        </div>
      }
      {apiError && (
          <div className={styles.error}>
            <ErrorField message={apiError}/>
          </div>
      )}
      {form?.endDate
      && <>
        {!isLoading
          ? <div className={styles.actions}>
            <button
              type="button"
              onClick={handleSubmit(onSubmit)}
            >
              Exporter
            </button>
          </div>
          : <div className={styles.actions}>
            Export en cours...
          </div>
        }
        </>
      }
    </div>
    </div>
  );
};

export default ExportCompare;
